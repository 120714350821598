import React, { Component } from 'react';
import _ from 'lodash';

import { getObscureTopics, getTopic } from '../utils/wikipedia';

class TopicSelection extends Component {
    constructor(props){
        super(props);
        this.state = {
            topics: [],
        };

        if(!this.isGm){
            this.setTopics();
        }
    }

    async setTopics() {
        const topics = await getObscureTopics();
        if(this.props.topic){
            topics.unshift(await getTopic(this.props.topic))
        }

        this.setState({topics});
    }

    onTopicSelect(e) {
        const topicId = parseInt(e.target.value, 10);
        const topic = this.state.topics.find(x => x.pageid === topicId);
        if(topic){
            this.props.setTopic(topic);
        }
    }

    render() {
        return (
            <div className="TopicSelection">
                {this.props.isGm &&
                    <div className="gm">
                    {this.props.topicCount < this.props.playerCount &&
                        <div className="header">Waiting for players to select a topic {this.props.topicCount}/{this.props.playerCount}</div> 
                    }
                    </div>
                }
                {!this.props.isGm && (
                    this.state.topics.length
                    ? <div className="topic-selector">
                        <select onChange={this.onTopicSelect.bind(this)} defaultValue={this.props.topic || "0"}>
                            {!this.props.topic && <option value="0" disabled>Select a topic</option>}
                            {this.state.topics.map(topic => 
                                <option value={topic.pageid} key={topic.pageid}>{topic.title}</option>
                            )}
                        </select>
                    </div>
                    : <div className="header">Loading topics...</div>
                )}

                <div className="players">
                {_.map(this.props.players, (topic, player) =>
                    <div className="player" key={player}>
                        <span>{player}</span>
                        <div>{topic ? '✅' : '🕙'}</div>
                    </div>
                )}

                {this.props.isGm &&
                    <div className="gm">
                    {this.props.topicCount >= this.props.playerCount &&
                        <button onClick={this.props.endSelection}>End selection</button> 
                    }
                    </div>
                }

                </div>
            </div>
        );
    }
}

export default TopicSelection;