import React, { Component } from 'react';

import { getTopic } from '../utils/wikipedia';

const MINIMUM_TIME = 10; // Seconds

class QuestionPhase extends Component {
    constructor(props){
        super(props);

        this.state = {
            topic: false,
            minTimeElapsed: false,
        }

        this.load();

        setTimeout(() => {
            this.setState({
                minTimeElapsed: true,
            });
        }, MINIMUM_TIME * 1000);
    }

    componentDidUpdate(prevProps) {
        if (this.props.topicId !== prevProps.topicId) {
            this.load();
        }
    }

    async load() {
        if(!this.props.topicId) return;

        this.setState({
            topic: await getTopic(this.props.topicId),
        });
    }

    render() {
        return (
            <div className="QuestionPhase">
                <div className="header">
                    Topic: {this.state.topic && this.state.topic.title}
                </div>
                {this.props.isGm
                    ? <>
                        <p>Pick the player who has read the article</p>
                        <div className="players">
                            {this.props.players.map(p =>
                                <div className="player" key={p}>
                                    <span>{p}</span>
                                    <button onClick={() => this.props.guess(p)} disabled={!this.state.minTimeElapsed}>Pick</button>
                                </div>
                            )}
                        </div>
                    </>
                    : <span className="status">Waiting for {this.props.gm} to make a guess...</span>
                }
            </div>
        );
    }
}

export default QuestionPhase;