import React, { Component } from 'react';

import { getTopic } from '../utils/wikipedia';

const MINIMUM_STUDY_TIME = 10; // Seconds

class StudyPhase extends Component {
    constructor(props){
        super(props);

        this.state = {
            topic: false,
            articleOpen: true,
            minStudyElapsed: false,
        }

        this.loadTopic();
        this.loadSelectedTopic();

        setTimeout(() => {
            this.setState({
                minStudyElapsed: true,
            });
        }, MINIMUM_STUDY_TIME * 1000);
    }

    componentDidUpdate(prevProps) {
        if (this.props.topicId !== prevProps.topicId) {
            this.loadTopic();
        }
        if (this.props.selectedTopicId !== prevProps.selectedTopicId) {
            this.loadSelectedTopic();
        }
    }

    async loadTopic() {
        if(!this.props.topicId) return;
        
        this.setState({
            topic: await getTopic(this.props.topicId),
        });
    }

    async loadSelectedTopic(){
        if(!this.props.selectedTopicId) return;

        this.setState({
            selectedTopic: await getTopic(this.props.selectedTopicId),
        });
    }

    render() {
        return (
            <div className="StudyPhase">
                {this.state.topic &&
                    <div className="wikipedia-holder">
                        <iframe 
                            className={'wikipedia' + (this.state.articleOpen ? ' -open' : '')} 
                            title={this.state.topic.title} 
                            src={this.state.topic.fullurl}
                        ></iframe>
                        {this.state.selectedTopic &&
                            <div className="header selected-topic">
                                Selected topic: {this.state.selectedTopic.title} 
                            </div>
                        }
                    </div>
                }

                {this.props.isGm &&
                    <div className="gm">
                        <span className="status">
                            The players are currently studying their topics...
                        </span>
                        <div className="actions">
                            <button onClick={this.props.endStudy} disabled={!this.state.minStudyElapsed}>End study</button>
                        </div>
                    </div>
                }
            </div>
        );
    }
}

export default StudyPhase;