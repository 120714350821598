import React, { Component } from 'react';

class MainMenu extends Component {
    constructor(props) {
      super(props);
      this.state = {};

      this.nameInput = React.createRef();
      this.gameInput = React.createRef();
    }

    handleSubmit(e) {
        if(this.nameInput.current.value.indexOf('.') === -1){
          this.props.setName(this.nameInput.current.value);
        }
        if(this.gameInput.current.value.indexOf('.') === -1){
          this.props.setGame(this.gameInput.current.value);
        }

        e.preventDefault();
    }
  
    render() {
      return (
        <div className="MainMenu">
            <form onSubmit={e => this.handleSubmit(e)}>
                <h2>Player name</h2>
                <input type="text" name="name" id="name" defaultValue={this.props.name} ref={this.nameInput} />
                <h2>Game ID</h2>
                <input type="text" name="game" id="game" defaultValue={this.props.game} ref={this.gameInput} />
                <div className="actions">
                  <input type="submit" value="Submit" />
                </div>
            </form>
        </div>
      );
    }
}

export default MainMenu;