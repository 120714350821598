import React, { Component } from 'react';

import 'normalize.css';
import './App.scss';

import firebase from '@firebase/app';
import '@firebase/firestore';

import Game from './components/Game';
import MainMenu from './components/MainMenu';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

var config = {
  apiKey: "AIzaSyA2-lpQCCGGixGDxTBwAp5QXh-Q5WVP6VQ",
  authDomain: "confidence-over-knowledge.firebaseapp.com",
  databaseURL: "https://confidence-over-knowledge.firebaseio.com",
  projectId: "confidence-over-knowledge",
  storageBucket: "confidence-over-knowledge.appspot.com",
  messagingSenderId: "232116283422",
  appId: "1:232116283422:web:6830ca93c059f84a"
};
firebase.initializeApp(config);

// Initialize Cloud Firestore through Firebase
var db = firebase.firestore();

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      player: "",
      game: "",
    }
  }


  componentDidMount(){
    const params = new URLSearchParams(window.location.search);
    if(params.has('game')){
      this.setGame(params.get('game'));
    }

    if(window.localStorage.hasOwnProperty('name')){
      this.setName(window.localStorage.getItem('name'));
    }
  }

  setGame(value) {
    const params = new URLSearchParams(window.location.search);
    params.set('game', value);
    window.history.replaceState(false, false, '?' + params.toString());

    this.setState({
      game: value,
    });
  }
  setName(value) {
    window.localStorage.setItem('name', value);

    this.setState({
      player: value,
    });
  }

  toast = (text) => toast(text, {
      type: toast.TYPE.INFO,
      hideProgressBar: true,
    }); 

  render() {
    return (
      <div className="App">
        {this.state.game && this.state.player
          ? <Game db={db} id={this.state.game} player={this.state.player} leave={() => this.setGame('')} toast={this.toast} />
          : <MainMenu game={this.state.game} name={this.state.player} setGame={this.setGame.bind(this)} setName={this.setName.bind(this)} />
        }
        <ToastContainer />
      </div>
    );
  }
}

export default App;
