import _ from 'lodash';

const BASE_API_URL = 'https://en.wikipedia.org/w/api.php?origin=*';

const OBSCURE_ARTICLES_QUERY = `${ BASE_API_URL }&action=query&format=json&prop=&list=allpages&apfrom=a&apfilterredir=nonredirects&aplimit=500`;
const MIN_PAGE_SIZE = 25000;
const MAX_PAGE_SIZE = 30000;
const PAGE_SIZE_SPREAD = 50;

const MAX_TOPIC_COUNT = 50;
const MIN_TOPIC_COUNT = 10;

const TERM_BLACK_LIST = [
    'list of', // Lists are boring
    '(', // Parentheses often reveal too much informatio
    ' in ', // Descriptive
    'battle',
    ' ', // Only single words
];

function isBlackListedTopic(topic) {
    return TERM_BLACK_LIST.every(term => topic.title.toLowerCase().indexOf(term.toLowerCase()) === -1 );
}

async function getObscureTopics() {
    const start = MIN_PAGE_SIZE + Math.floor(Math.random() * (MAX_PAGE_SIZE - MIN_PAGE_SIZE));
    const end = start + PAGE_SIZE_SPREAD;

    const response = await fetch(`${ OBSCURE_ARTICLES_QUERY }&apminsize=${ start }&apmaxsize=${ end }`);
    const { query } = await response.json();
    let {allpages: topics } = query;

    topics = topics.filter(isBlackListedTopic);
    topics = _.sampleSize(topics, MAX_TOPIC_COUNT);

    if(topics.count < MIN_TOPIC_COUNT){
        return getObscureTopics();
    }

    return topics
}

const SINGLE_ARTICLE_QUERY = `${ BASE_API_URL }&action=query&format=json&prop=info&inprop=url`;
async function getTopic(topicId){
    const response = await fetch(`${ SINGLE_ARTICLE_QUERY }&pageids=${ topicId }`);
    const { query } = await response.json();
    const topic = _.get(query, ['pages', topicId], false);

    return topic;
}

export {
    getObscureTopics,
    getTopic,
}