import React, { Component } from 'react';
import firebase from '@firebase/app';
import _ from 'lodash';

import Lobby from './Lobby';
import TopicSelection from './TopicSelection';
import StudyPhase from './StudyPhase';
import QuestionPhase from './QuestionPhase';
import EndScreen from './EndScreen';

import copy from 'copy-to-clipboard';


const PHASE = {
    LOBBY: 0,
    TOPIC_SELECTION: 1,
    STUDY_PHASE: 2,
    QUESTION_PHASE: 3,
    END_SCREEN: 4,
}

class Game extends Component {
    constructor(props) {
      super(props);
      this.state = {
          unsubscribe: () => {},
      };

      this.load();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.id !== prevProps.id) {
            this.load();
        }
        if (prevState.hasOwnProperty('phase') && this.state.phase !== prevState.phase) {
            this.handleNewPhase();
        }


        this.join();
    }

    async load() {
        this.state.unsubscribe();

        await this.create();
        
        const unsubscribe = this.docRef().onSnapshot(snapshot => {
            this.setState({
                ...snapshot.data(),
                unsubscribe,
            });
        });
    }

    join() {
        if(!this.state.hasOwnProperty('players')) return;
        if(this.state.players.hasOwnProperty(this.props.player) && this.state.players[this.props.player] !== false) return;
        if(this.isGm()) return;

        this.docRef().update({
            [`players.${ this.props.player }`]: '',
        });
    }

    async create() {
        const doc = await this.docRef().get();
        if(!doc.exists){
            await this.docRef().set({
                gm: this.props.player,
                phase: PHASE.LOBBY,
                players: {},
            });
        }
    }

    async reset() {
        if(!this.isGm) return;

        await this.docRef().update({
            answer: '',
            guess: '',
            topic: 0,
            phase: PHASE.LOBBY,
            players: _.mapValues(this.state.players, () => ''),
        });
    }

    docRef() {
        return this.props.db.doc(`games/${ this.props.id.toLowerCase() }`);
    }

    kickPlayer(name) {
        this.docRef().update({
            [`players.${ name }`]: firebase.firestore.FieldValue.delete(),
        });
    }

    nextPhase() {
        if(!this.isGm) return;

        this.docRef().update({
            phase: (this.state.phase + 1) % _.size(PHASE),
        });
    }

    handleNewPhase() {
        switch(this.state.phase){
            case PHASE.LOBBY:
                this.reset();
                break;
            case PHASE.TOPIC_SELECTION:
                break;
            case PHASE.STUDY_PHASE:
                setTimeout(() => {
                    this.pickTopic();
                }, 2000);
                break;
            case PHASE.QUESTION_PHASE:
                break;
            case PHASE.END_SCREEN:
                this.awardScore();
                break;
            default:
                break;
        }
    }

    isGm() {
        return this.props.player === this.state.gm;
    }

    makeGm(newGm){
        if(!this.isGm()) return;

        this.docRef().update({
            gm: newGm,
            [`players.${ this.state.gm }`]: '',
            [`players.${ newGm }`]: firebase.firestore.FieldValue.delete(),
        });
    }

    setTopic(topic){
        if(this.isGm()) return;

        this.docRef().update({
            [`players.${ this.props.player }`]: topic.pageid,
        });
    }

    pickTopic() {
        if(!this.isGm()) return;

        this.docRef().update({
            topic: _.sample(this.state.players),
        });
    }

    handleGuess(player){
        if(!this.isGm) return;

        this.docRef().update({
            guess: player,
            answer: _.findKey(this.state.players, _.partial(_.isEqual, this.state.topic))
        });
        this.nextPhase();
    }

    isCorrectGuess(){
        return this.state.guess === this.state.answer;
    }

    maybeFakeSelectedTopic(){
        if(!this.state.players || !this.state.topic) return false;
        if(this.state.players.length < 2) return false; // Prevent infinite loop

        const playerTopic = this.state.players[this.props.player];
        let maybeFakeTopic = this.state.topic;
        
        while(playerTopic === maybeFakeTopic){
            maybeFakeTopic = _.sample(this.state.players);
        }

        return maybeFakeTopic;
    }

    awardScore(){
        if(!this.isGm) return;

        const scores = _.mapValues({[this.state.gm]: '', ...this.state.players}, (_unused, player) => {
            let score = _.get(this.state, ['scores', player], 0);
            if(player === this.state.gm && this.isCorrectGuess()){
                score++;
            }else if(player === this.state.guess){
                score++;
            }
            return score;
        });
        this.docRef().update({
            scores,
        });
    }

    getShareLink(){
        return `${ window.location.origin }${ window.location.pathname }?game=${ this.props.id }`
    }
    share() {
        if(navigator.share){
            navigator.share({
                url: this.getShareLink(),
            })
              .catch(() => this.shareFallback());
        }else{
            this.shareFallback();
        }
    }
    shareFallback(){
        if(copy(this.getShareLink())){
            this.props.toast('Copied link!');
        }
    }
  
    render() {
      return (
        <div className="Game">
            {{
                [PHASE.LOBBY]: <Lobby
                    isGm={this.isGm()}
                    id={this.props.id}
                    player={this.props.player}
                    players={Object.keys(this.state.players || {})}
                    scores={this.state.scores || {}}
                    gm={this.state.gm}
                    kickPlayer={this.kickPlayer.bind(this)}
                    makeGm={this.makeGm.bind(this)}
                    startGame={this.nextPhase.bind(this)}
                    leave={this.props.leave}
                    share={this.share.bind(this)}
                />,
                [PHASE.TOPIC_SELECTION]: <TopicSelection
                    isGm={this.isGm()}
                    topic={(this.state.players || {})[this.props.player]}
                    topicCount={_.size(_.filter(_.values(this.state.players)))}
                    playerCount={_.size(this.state.players)}
                    players={this.state.players}
                    setTopic={this.setTopic.bind(this)}
                    endSelection={this.nextPhase.bind(this)}
                />,
                [PHASE.STUDY_PHASE]: <StudyPhase
                    isGm={this.isGm()}
                    topicId={this.state.players && this.state.players[this.props.player]}
                    selectedTopicId={this.maybeFakeSelectedTopic()}
                    endStudy={this.nextPhase.bind(this)}
                />,
                [PHASE.QUESTION_PHASE]: <QuestionPhase
                    isGm={this.isGm()}
                    gm={this.state.gm}
                    topicId={this.state.topic}
                    players={Object.keys(this.state.players || {})}
                    guess={this.handleGuess.bind(this)}
                />,
                [PHASE.END_SCREEN]: <EndScreen
                    isGm={this.isGm()}
                    isCorrect={this.isCorrectGuess()}
                    guess={this.state.guess}
                    answer={this.state.answer}
                    gm={this.state.gm}
                    player={this.props.player}
                    players={Object.keys(this.state.players || {})}
                    topicId={this.state.topic}
                    newGame={this.nextPhase.bind(this)}
                />,
            }[this.state.phase]}
            {this.isGm() && <button onClick={this.reset.bind(this)} className="reset-game btn-alt">Restart</button>}
        </div>
      );
    }
}

export default Game;