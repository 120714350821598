import React, { Component } from 'react';
import _ from 'lodash';

import { getTopic } from '../utils/wikipedia';

class EndPhase extends Component {
    constructor(props){
        super(props);

        this.state = {
            topic: false,
        }

        this.load();
    }

    componentDidUpdate(prevProps) {
        if (this.props.topicId !== prevProps.topicId) {
            this.load();
        }
    }

    async load() {
        if(!this.props.topicId) return;

        this.setState({
            topic: await getTopic(this.props.topicId),
        });
    }

    render() {
        return (
            <div className={'EndScreen ' + (this.props.isCorrect ? '-correct' : '-wrong')}>
                <div className="result header">{this.props.isCorrect
                    ? 'Correct'
                    : 'Wrong'
                }!</div>
                <p className="answer">
                    It was {this.props.answer}'s topic {!this.props.isCorrect && `but ${this.props.gm} guessed ${this.props.guess}`}
                </p>

                <div className="players">
                {_.map([this.props.gm, ...this.props.players], player =>
                    <div className={
                        "player"
                        + (this.props.player === player ? ' -current' : '')
                        + (this.props.gm === player ? ' -gm' : '')
                    } key={player}>
                        <span>{player}</span>
                        <div className="score">
                            {player === this.props.gm && this.props.isCorrect && '+1'}
                            {player === this.props.guess && '+1'}
                        </div>
                    </div>
                )}
                </div>

                <div className="actions">
                    {this.state.topic && <a href={this.state.topic.fullurl} className="btn" target="_blank" rel="noopener noreferrer">View article</a>}
                    {this.props.isGm && <button onClick={this.props.newGame}>New game</button> }
                </div>
            </div>
        );
    }
}

export default EndPhase;