import React, { Component } from 'react';
import _ from 'lodash';

class Lobby extends Component {
    render() {
      return (
        <div className="Lobby">
          <div className="info header">
            Game ID: {this.props.id}
          </div>
          <div className="players">
            {[this.props.gm, ...this.props.players].map(p =>
              <div className={
                "player"
                + (this.props.player === p ? ' -current' : '')
                + (this.props.gm === p ? ' -gm' : '')
              } key={p}>
                <span>{p}</span>
                <span>{_.get(this.props.scores, [p], '')}</span>
                {this.props.isGm && !(this.props.gm === p) &&
                  <div>
                    <button className="kick" onClick={() => this.props.kickPlayer(p)}>x</button>
                    <button className="make-gm" onClick={() => this.props.makeGm(p)}>{'👑'}</button>
                  </div>
                }
              </div>
            )}
          </div>
          <div className="actions">
            {this.props.isGm 
              ? <button onClick={this.props.startGame} disabled={!this.props.players || this.props.players.length < 2}>Start game</button>
              : <span className="status">Waiting for {this.props.gm} to start the game...</span>
            }
            <div>
              <button onClick={this.props.leave} className="btn-alt leave">Leave</button>
              <button onClick={this.props.share} className="btn-alt share">Invite friends</button>
            </div>
          </div>
        </div>
      );
    }
}

export default Lobby;